<template>
    <div class="alipay-box">
        <van-image style="margin:0 auto;" width="3rem" height="3rem" :src="alipay" />
        <div style="margin:2rem 0;">支付失败<span v-if="isCanPayment">，请重新支付</span></div>
        <van-icon name="clear" color="#FF413D" size="4rem" />
        <div style="margin-top:2rem;" @click="handleClickAgain" v-if="isCanPayment">
            <van-button round style="width:100%" size="small" type="info">重新支付</van-button>
        </div>
    </div>
</template>
<script>
import bshop_global from "@/libs/global"
import { getUrlKey } from "@/libs/utils"
import alipay from "@/assets/image/aipay.png"
export default {
    name:"alipay-error",
    data(){
        return{
            alipay:alipay,
            orderId:"",
            isCanPayment:true  //是否可以重新支付
        }
    },
    mounted() {
    // 切换页面时滚动条自动滚动到顶部
        window.scrollTo(0,0);
        this.orderId = getUrlKey("orderid")
        if(!this.orderId){
            this.isCanPayment = false
        }
    },
    methods:{
        //重新支付
        handleClickAgain(){
            if(this.orderId){
                window.location.href =  bshop_global.bShopUrl +"/#/payinfo?code=" + this.orderId+"&customerId=" + localStorage.getItem('customerId') +"&sysOfficeId="+ localStorage.getItem('sysOfficeId');
            }
        }
    }
}
</script>
<style scoped>
    .alipay-box{
        padding:0 5%;
        position: fixed;
        width: 90%;
        min-height: 100%;
        background: #f7f7f7;
        padding-top: 4rem;
        text-align: center;
        font-size: 0.88rem;
        color: #999999;
    }
</style>